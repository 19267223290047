<template>
    <fragment>
        <with-addon-before-info-panel
            :title="panelTitle"
            class="has-margin-top"
            :data-source="dataSource"
            :columns="contentColumns"
            :addon-before-columns="addonBeforeColumns"
        >
            <custom-button-space v-if="showOperation" slot="extra">
                <template v-if="guaranteeReturnState">
                    <popconfirm-button
                        button-type="danger"
                        button-text="发起退保"
                        title="确定发起退保？"
                        :loading="loading.guaranteeReturn"
                        @on-confirm="handleInitiateGuaranteeReturn"
                    />
                </template>

                <template v-if="guaranteeReturnDetailState">
                    <a-button type="gray" @click.stop="handleJumpToReturnDetail">
                        退保详情
                    </a-button>
                </template>

                <template v-if="guaranteeCancelRenewalState">
                    <popconfirm-button
                        button-text="取消续保"
                        title="确定取消续保？"
                        :loading="loading.cancelRenewal"
                        @on-confirm="handleCancelRenewal"
                    />
                </template>

                <template v-if="guaranteeBankState">
                    <a-button
                        type="primary"
                        :loading="loading.bankGuaranteeHandling"
                        @click.stop="handleClickBankGuaranteeHandling"
                    >
                        银行保函办理
                    </a-button>
                </template>

                <template v-if="guaranteeBankShowState">
                    <a-button
                        type="primary"
                        :loading="loading.lookOver"
                        @click.stop="handleLookOverApplyInfo"
                    >
                        查看申请资料
                    </a-button>
                </template>

                <template v-if="guaranteeAddState">
                    <a-button
                        type="primary"
                        :loading="loading.toInsure"
                        @click.stop="handleToInsure"
                    >
                        保险办理
                    </a-button>
                </template>

                <template v-if="guaranteePolicyAddState">
                    <a-button
                        type="primary"
                        @click.stop="handlePolicyUpload"
                    >
                        保单上传
                    </a-button>
                </template>
            </custom-button-space>

            <template #emptySlot>
                <div class="fixed-deposit-info-panel--empty">{{ emptyText }}</div>
            </template>
            <template v-if="showMsg" #bodyTop>
                <a-alert type="warning" message="试运行期间,积极鼓励采用电子保函办理方式,2024年10月1日起正式运行,所有新开工项目,原则上均应采用线上办理电子保函,不再开放线下纸质保函上传入口。" />
            </template>
        </with-addon-before-info-panel>
        <component
            :is="modalComponentName"
            v-bind="modalProp"
            @on-close="handleCloseModal"
            @on-refresh="$emit('on-refresh')"
        />

        <!-- 续保列表 -->
        <template v-if="!renewalFlag">
            <template v-for="(item, index) in renewalVOList">
                <guarantee-panel
                    :key="index"
                    :renewal-flag="true"
                    :data-source="item"
                    :show-operation="showOperation"
                    @on-refresh="$emit('on-refresh')"
                />
            </template>
        </template>
    </fragment>
</template>
<script>
import { Button as AButton, Alert as AAlert } from 'ant-design-vue'
import Fragment from '@components/Fragment'
import PopconfirmButton from '@components/PopconfirmButton'
import CustomButtonSpace from '@components/CustomButtonSpace'
import WithAddonBeforeInfoPanel from '@weights/WithAddonBeforeInfoPanel'

import { hasOwnProperty, isEmpty } from '@utils'
import { parserPanel } from '@/weights/CreatePage/parse'

import { LOAD_LAYOUT_URL } from '@config'
import { getGuaranteePanelTitle, getContentColumnsByType, ADDON_BEFORE_COLUMNS, EMPTY_TEXT_GETTER_STRATEGY } from './config'
import { HIDE_ENT_PWD_FORGET_BUTTON_ENABLED } from '@constant/enum'

import commonService from '@service/commonService'
import cancelRenewalService from '@service/marginManagement/marginPaymentDetail/cancelRenewalService'
import getInsuredJumpLinkService from '@service/marginManagement/marginPaymentDetail/getInsuredJumpLinkService'
import initiateGuaranteeReturnService from '@service/marginManagement/marginPaymentDetail/initiateGuaranteeReturnService'

export default {
    name: 'GuaranteePanel',
    components: {
        AButton,
        Fragment,
        PopconfirmButton,
        CustomButtonSpace,
        WithAddonBeforeInfoPanel,
        AAlert,
        PolicyUploadModal: () => import('./PolicyUploadModal'),
        HandlingApplyModal: () => import('./HandlingApplyModal'),
        LookOverApplyInfoModal: () => import('./LookOverApplyInfoModal')
    },
    props: {
        dataSource: {
            type: Object,
            default: () => ({})
        },
        showOperation: {
            type: Boolean,
            default: true
        },
        renewalFlag: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // 加载状态
            loading: {
                // 保险办理
                toInsure: false,
                // 银行保函办理
                bankGuaranteeHandling: false
            },

            // 弹框相关状态
            modalProp: {
                visible: false
            },
            modalComponentName: undefined,

            // 前置内容项配置
            addonBeforeColumns: Object.freeze(ADDON_BEFORE_COLUMNS)
        }
    },
    computed: {
        // 项目编号
        projectCode() {
            return this.$route.query.projectCode
        },
        // 保函类型
        guaranteeType() {
            return this.dataSource.guaranteeType
        },
        // 订单编号
        orderNo() {
            return this.dataSource.orderNo
        },
        // 续保列表
        renewalVOList() {
            if (this.renewalFlag) return
            return this.dataSource.guaranteeVOList || []
        },
        // 是否展示 `银行保函办理` 按钮
        guaranteeBankState() {
            return this.dataSource.guaranteeBankState
        },
        // 是否展示 `查看申请资料` 按钮
        guaranteeBankShowState() {
            return this.dataSource.guaranteeBankShowState
        },
        // 是否展示 `保险办理` 按钮
        guaranteeAddState() {
            return this.dataSource.guaranteeAddState
        },
        // 是否显示 `保单上传` 按钮
        guaranteePolicyAddState() {
            return this.dataSource.guaranteePolicyAddState
        },
        // 是否显示 `发起退保` 按钮
        guaranteeReturnState() {
            return this.dataSource.guaranteeReturnState
        },
        // 是否显示 `取消退保` 按钮
        guaranteeReturnDetailState() {
            return this.dataSource.guaranteeReturnDetailState
        },
        // 是否显示 `取消续保` 按钮
        guaranteeCancelRenewalState() {
            return this.renewalFlag && this.dataSource.guaranteeCancelRenewalState
        },
        // FIXME: 临时使用忘记密码开关显示提示文案（合肥地区需求）
        showMsg() {
            const hideEntPwdForgetButton = this.$store.getters.initInformation?.hideEntPwdForgetButton
            return hideEntPwdForgetButton === HIDE_ENT_PWD_FORGET_BUTTON_ENABLED
        },
        // 面板标题
        panelTitle() {
            return getGuaranteePanelTitle(this.dataSource, this.renewalFlag)
        },
        // 空文本（提示：需要展示时才会有对应值）
        emptyText() {
            const { guaranteeType } = this

            if (!hasOwnProperty(EMPTY_TEXT_GETTER_STRATEGY, guaranteeType)) {
                return EMPTY_TEXT_GETTER_STRATEGY.guaranteeTypeUnknown()
            }

            return EMPTY_TEXT_GETTER_STRATEGY[guaranteeType](this.dataSource, this.renewalFlag)
        },
        // 主体内容项配置
        contentColumns() {
            if (!isEmpty(this.emptyText)) return []
            return getContentColumnsByType(this.guaranteeType)
        }
    },
    methods: {
        // 获取办理资料【申请】布局模板
        async getHandlingInfoApplyTpl() {
            try {
                const param = {
                    url: LOAD_LAYOUT_URL,
                    params: {
                        orderNo: this.orderNo,
                        projectCode: this.projectCode,
                        layoutCode: 'ent_margin_bank_apply_file_detail'
                    }
                }

                const result = await commonService(param)

                return Object.freeze(parserPanel(result))
            } catch (e) {
                // eslint-disable-next-line
                return Promise.reject({ method: 'error', message: e.message })
            }
        },
        // 获取办理资料【查看】布局模板
        async getHandlingInfoLookOverTpl() {
            try {
                const param = {
                    url: LOAD_LAYOUT_URL,
                    params: {
                        orderNo: this.orderNo,
                        layoutCode: 'ent_margin_bank_apply_file_41'
                    }
                }

                const result = await commonService(param)

                return Object.freeze(parserPanel(result))
            } catch (e) {
                // eslint-disable-next-line
                return Promise.reject({ method: 'error', message: e.message })
            }
        },
        // 点击保证保险
        async handleToInsure() {
            const newWindow = window.open()

            try {
                this.changeBtnLoading('toInsure', true)

                const formData = {
                    orderNo: this.orderNo,
                    renewalFlag: this.renewalFlag
                }

                const result = await getInsuredJumpLinkService(formData)

                newWindow.location = JSON.parse(result.body || '{}').url
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)

                newWindow && newWindow.close()
            } finally {
                this.changeBtnLoading('toInsure', false)
            }
        },
        // 点击银行保函办理
        async handleClickBankGuaranteeHandling() {
            try {
                this.changeBtnLoading('bankGuaranteeHandling', true)

                const tpl = await this.getHandlingInfoApplyTpl()

                const extraProp = {
                    handlingInfoTpl: tpl,
                    orderNo: this.orderNo
                }

                this.handleOpenModal('HandlingApplyModal', extraProp)
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.changeBtnLoading('bankGuaranteeHandling', false)
            }
        },
        // 查看申请资料
        async handleLookOverApplyInfo() {
            try {
                this.changeBtnLoading('lookOver', true)

                const tpl = await this.getHandlingInfoLookOverTpl()

                const extraProp = {
                    handlingInfoTpl: tpl,
                    orderNo: this.orderNo
                }

                this.handleOpenModal('LookOverApplyInfoModal', extraProp)
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.changeBtnLoading('lookOver', false)
            }
        },
        // 取消续保
        async handleCancelRenewal() {
            try {
                this.changeBtnLoading('cancelRenewal', true)

                // eslint-disable-next-line
                await cancelRenewalService({ orderNo: this.orderNo })

                this.$message.success('操作成功')
                this.$emit('on-refresh')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.changeBtnLoading('cancelRenewal', false)
            }
        },
        // 发起退保
        async handleInitiateGuaranteeReturn() {
            const newWindow = window.open()

            try {
                this.changeBtnLoading('guaranteeReturn', true)

                // eslint-disable-next-line
                const result = await initiateGuaranteeReturnService({ orderNo: this.orderNo })

                newWindow.location = result
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)

                newWindow && newWindow.close()
            } finally {
                this.changeBtnLoading('guaranteeReturn', false)
            }
        },
        // 跳转至退保详情页面
        handleJumpToReturnDetail() {
            const { guaranteeReturnDetailUrl } = this.dataSource

            if (isEmpty(guaranteeReturnDetailUrl)) {
                this.$message.error('未找到保单退保详情地址')
                return
            }

            window.open(guaranteeReturnDetailUrl)
        },
        // 保单上传
        handlePolicyUpload() {
            // eslint-disable-next-line
            this.handleOpenModal('PolicyUploadModal', { orderNo: this.orderNo })
        },
        changeBtnLoading(btnType, bool) {
            this.$set(this.loading, btnType, bool)
        },
        handleOpenModal(modalName, extraProp = {}) {
            this.modalProp = {
                visible: true,
                ...extraProp || {}
            }
            this.modalComponentName = modalName
        },
        handleCloseModal() {
            this.$set(this.modalProp, 'visible', false)
        }
    }
}
</script>
